import {
  listTrees as listTreeService,
  listCollection as listCollectionService,
  createTree as createTreeService,
  updateTree as updateTreeService,
  removeTree as removeTreeService,
  removeCollections as removeCollectionsService,
  getDonationList as getDonationListService,
  getCollectionByIdForIndivi as getCollectionByIdForIndiviService,
  myPlantationCount,
  listCategories as listCategoriesService,
  getCollectionByRegion as getCollectionByRegionService,
  approveCollectionById,
} from "../../services/DonationService";

import {
  PREVIEW_TREE_DONATION,
  LIST_TREE_DONATION,
  ADD_TREE_DONATION,
  UPDATE_TREE_DONATION,
  REMOVE_TREE_DONATION,
  LIST_POND_DONATION,
  ADD_POND_DONATION,
  UPDATE_POND_DONATION,
  REMOVE_POND_DONATION,
  LIST_COLLECTION_DONATION,
  ADD_COLLECTION_DONATION,
  UPDATE_COLLECTION_DONATION,
  REMOVE_COLLECTION_DONATION,
  LIST_TREE,
  LIST_POND,
  LIST_COLLECTION,
  UPDATE_COLLECTION_STATUS,
  ADD_TREE,
  UPDATE_TREE,
  REMOVE_TREE,
  MY_PLANTATION_COUNT,
  COLLECTION_ALL_LIST_ACTION,
  COLLECTION_BY_REGION_ACTION,
} from "./DonationTypes";

export function treeDonationPreviewAction(payload) {
  return {
    type: PREVIEW_TREE_DONATION,
    payload,
  };
}

export function collectionAllList(id) {
  return (dispatch) => {
    getCollectionByIdForIndiviService(id)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            collectionAllListConfirm({
              collections: res.data.collections,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function collectionAllListConfirm(payload) {
  return { type: COLLECTION_ALL_LIST_ACTION, payload };
}

export function collectionRegionList(region) {
  return (dispatch) => {
    getCollectionByRegionService(region)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            collectionRegionListConfirm({
              regionCollection: res.data.collection,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function collectionRegionListConfirm(payload) {
  return { type: COLLECTION_BY_REGION_ACTION, payload };
}

export function approveCollectionStatus(collectionId, status) {
  return (dispatch) => {
    approveCollectionById(collectionId, { status })
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(approveCollectionStatusConfirm(res.data.updatedCollection));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function approveCollectionStatusConfirm(payload) {
  return {
    type: UPDATE_COLLECTION_STATUS,
    payload,
  };
}

export function collectionList(userId) {
  return (dispatch) => {
    listCollectionService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            collectionListConfirm({
              collections: res.data.collections,
              requests: res.data.requests,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function collectionListConfirm(payload) {
  return {
    type: LIST_COLLECTION,
    payload,
  };
}

export function listTreeDonations(userId) {
  return (dispatch) => {
    getDonationListService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            listTreeDonationsConfirm({
              list: res.data.donations,
              requests: res.data.requests,
            })
          );
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function listPondDonations(userId) {
  return (dispatch) => {
    getDonationListService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            listPondDonationsConfirm({
              list: res.data.donations,
              requests: res.data.requests,
            })
          );
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function listCollectionDonations(userId) {
  return (dispatch) => {
    getDonationListService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            listCollectionDonationsConfirm({
              list: res.data.donations,
              requests: res.data.requests,
            })
          );
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function treePlantationCount(userId) {
  return (dispatch) => {
    myPlantationCount(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(getTreePlantationCountConfirm(res.data.plantationCount));
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function getTreePlantationCountConfirm(payload) {
  return {
    type: MY_PLANTATION_COUNT,
    payload,
  };
}

export function listTreeDonationsConfirm(payload) {
  return {
    type: LIST_TREE_DONATION,
    payload,
  };
}

export function listPondDonationsConfirm(payload) {
  return {
    type: LIST_POND_DONATION,
    payload,
  };
}

export function listCollectionDonationsConfirm(payload) {
  return {
    type: LIST_COLLECTION_DONATION,
    payload,
  };
}

export function addTreeDonation(payload) {
  return {
    type: ADD_TREE_DONATION,
    payload,
  };
}

export function addPondDonation(payload) {
  return {
    type: ADD_POND_DONATION,
    payload,
  };
}

export function addCollectionDonation(payload) {
  return {
    type: ADD_COLLECTION_DONATION,
    payload,
  };
}

export function updateCollectionDonation(payload) {
  return {
    type: UPDATE_COLLECTION_DONATION,
    payload,
  };
}

export function listTrees() {
  return (dispatch) => {
    listCategoriesService("tree")
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(listTreesConfirm(res.data.categories));
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function listPonds() {
  return (dispatch) => {
    listCategoriesService("pond")
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(listPondsConfirm(res.data.categories));
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export function listCollections() {
  return (dispatch) => {
    listCategoriesService("collection")
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(listCollectionsConfirm(res.data.categories));
        } else if (res.data.status == 417) {
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}


export function removeCollections(collectionId) {
  return (dispatch) =>
    removeCollectionsService(collectionId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(removeCollectionConfirm(collectionId));
        } else {
          console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeCollectionConfirm(payload) {
  return {
    type: REMOVE_COLLECTION_DONATION,
    payload,
  };
}

export function listPondsConfirm(payload) {
  return {
    type: LIST_POND,
    payload,
  };
}

export function listCollectionsConfirm(payload) {
  return {
    type: LIST_COLLECTION,
    payload,
  };
}

export function listTreesConfirm(payload) {
  return {
    type: LIST_TREE,
    payload,
  };
}
