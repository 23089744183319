import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { getRoleName, getUserId } from "../../../store/selectors/AuthSelectors";

import {
  approveCollectionStatus,
  collectionList,
  removeCollections,
} from "../../../store/actions/DonationActions";
import {
  getCollectionRequests,
  getCollections,
} from "../../../store/selectors/CollectionSelector";
import moment from "moment";
import { Button, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { nFormatter } from "../../../api/constants";
import { Badge } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import { toast } from "react-toastify";
import {
  createInterestActions,
  listInterestAction,
  removeInterestAction,
} from "../../../store/actions/InterestActions";
import { getInterests } from "../../../store/selectors/InterestSelector";

const CollectionList = (props) => {
  const dispatch = useDispatch();
  const _d = useSelector((state) => getCollections(state));
  const roleName = useSelector(getRoleName);
  const requests = useSelector(getCollectionRequests);
  const interest = useSelector(getInterests);
  const [wishlistItems, setWishlistItems] = useState(
    JSON.parse(localStorage.getItem("wishlistItems")) || []
  );

  const [filters, setFilters] = useState({ search: "", region: "" });

  const [defaultData, setDefaultData] = useState([]);
  const [data, setData] = useState([]);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [activeTab, setActiveTab] = useState("my");
  const userId = useSelector(getUserId);
  const history = useHistory();

  const toggleWishlist = (collection) => {
    let updatedWishlist;
    if (wishlistItems.includes(collection.id)) {
      updatedWishlist = wishlistItems.filter((id) => id !== collection.id);
      setWishlistItems(updatedWishlist);
      // toast.warning("You are not Interested!");
      dispatch(removeInterestAction(userId, collection.id));
    } else {
      updatedWishlist = [...wishlistItems, collection.id];
      setWishlistItems(updatedWishlist);
      // toast.success("You are Interested!");
      dispatch(createInterestActions({ userId, collectionId: collection.id }));
    }
    localStorage.setItem("wishlistItems", JSON.stringify(updatedWishlist));
  };

  const ThreeDots = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#ffffff" cx="5" cy="12" r="2"></circle>
        <circle fill="#ffffff" cx="12" cy="12" r="2"></circle>
        <circle fill="#ffffff" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  let tabData = [];
  if (roleName == "SuperAdmin") {
    tabData = [
      {
        name: "My Projects",
        onClick: () => setActiveTab("my"),
        badge: data.length,
      },
      {
        name: "Pending Requests",
        onClick: () => setActiveTab("pending"),
        badge: requests.length,
      },
    ];
  }

  useEffect(() => {
    if (defaultData.length === 0 && roleName === "SuperAdmin") {
      setDefaultData([..._d, ...requests]);
      setData([..._d, ...requests]);
    } else {
      setDefaultData([..._d]);
      setData([..._d]);
    }
  }, [_d, requests]);

  useEffect(() => {
    dispatch(collectionList(userId));
  }, []);

  useEffect(() => {
    dispatch(listInterestAction(userId));
  }, []);

  useEffect(() => {
    // Assuming that interest is an array of collection IDs that the user is interested in
    const userInterests = interest.map((item) => item.collectionId);
    setWishlistItems(userInterests);
  }, [interest]);

  function removeStatus(collectionId) {
    dispatch(removeCollections(collectionId));
  }

  function changeCollectionStatus(status, collectionId) {
    dispatch(approveCollectionStatus(collectionId, status));
  }

  function handleFilters(filters = { search: "", region: "" }) {
    const d = [...defaultData];
    let results = [];
    if (!filters.region && !filters.search) {
      results = d;
    } else if (filters.region && filters.search) {
      results = d
        .filter((item) => item.region === filters.region)
        .filter((item) =>
          item.title
            ? item.title.toLowerCase().indexOf(filters.search.toLowerCase()) !==
              -1
            : false
        );
    } else {
      if (filters.region) {
        results = d.filter((item) => item.region === filters.region);
      }

      if (filters.search) {
        results = d.filter((item) =>
          item.title
            ? item.title.toLowerCase().indexOf(filters.search.toLowerCase()) !==
              -1
            : false
        );
      }
    }

    setData(results);
  }

  return (
    <Fragment>
      <h2 className="text-black font-w600 mb-5">All Projects</h2>
      <div className="row col-sm-12 mb-5">
        <div className="col-sm-4">
          <input
            placeholder="Search..."
            className="form-control"
            onChange={(e) => {
              const currentFilters = { ...filters };
              handleFilters({ ...currentFilters, search: e.target.value });
              setFilters({ ...currentFilters, search: e.target.value });
            }}
          />
        </div>
        <div className="col-sm-4">
          <select
            placeholder="Select Region"
            className="form-control custom-select"
            onChange={(e) => {
              const currentFilters = { ...filters };
              handleFilters({ ...currentFilters, region: e.target.value });
              setFilters({ ...currentFilters, region: e.target.value });
            }}
          >
            <option value="">Select Region</option>
            <option value="Africa">Africa</option>
            <option value="Asia">Asia</option>
            <option value="Europe">Europe</option>
            <option value="North America">North America</option>
            <option value="South America">South America</option>
            <option value="Australia">Australia</option>
          </select>
        </div>
      </div>
      <div className="row">
        {data && data.length
          ? data.map((collection) => {
              const isItemInWishlist = wishlistItems.includes(collection.id);

              let variant = "success";
              if (collection.status === "Approved") {
                variant = "success";
              } else if (collection.status === "Pending") {
                variant = "info";
              } else {
                variant = "danger";
              }

              return (
                <div key={collection.id} className="col-lg-4 mb-4">
                  <div className="card" style={{ borderRadius: "20px" }}>
                    <img
                      src={collection.image.link}
                      alt={collection.title}
                      className="card-img-top"
                      style={{
                        minHeight: "350px",
                        height: "45%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{collection.title}</h5>
                      <p className="card-text">Type: {collection.type}</p>
                      <p className="card-text">
                        Budget:{" "}
                        {collection.budget
                          ? nFormatter(collection.budget)
                          : "N/A"}
                        <small>{collection.currency_code}</small>
                      </p>
                      <p className="card-text">Region: {collection.region}</p>
                      <p>
                        {moment(collection.createdAt).format("DD MMMM YYYY")}
                      </p>
                      <div
                        className="row"
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          zIndex: 2,
                        }}
                      >
                        <div
                          className="col-lg-10"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Badge className="mr-2" variant={variant}>
                            {collection.status}
                          </Badge>
                          <Button
                            onClick={() => toggleWishlist(collection)}
                            className="sharp i-false mr-2"
                          >
                            <i
                              className={
                                isItemInWishlist
                                  ? "fas fa-star text-warning"
                                  : "far fa-star text-secondary"
                              }
                            ></i>
                          </Button>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={variant}
                              className="sharp i-false"
                            >
                              {ThreeDots}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onSelect={() => {
                                  history.push("project/" + collection.id);
                                }}
                                variant="danger"
                                className="danger light sharp i-false"
                              >
                                Preview
                              </Dropdown.Item>
                              <Dropdown.Item
                                onSelect={() => {
                                  history.push("edit-project/" + collection.id);
                                }}
                                variant="danger"
                                className="danger light sharp i-false"
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Header>Change Status</Dropdown.Header>
                              {collection.status === "Approved" &&
                              roleName === "SuperAdmin" ? (
                                <div>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Pending",
                                        collection.id
                                      )
                                    }
                                  >
                                    Pending
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Rejected",
                                        collection.id
                                      )
                                    }
                                  >
                                    Reject
                                  </Dropdown.Item>
                                </div>
                              ) : collection.status === "Pending" &&
                                roleName === "SuperAdmin" ? (
                                <div>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Approved",
                                        collection.id
                                      )
                                    }
                                  >
                                    Approve
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Rejected",
                                        collection.id
                                      )
                                    }
                                  >
                                    Reject
                                  </Dropdown.Item>
                                </div>
                              ) : collection.status === "Rejected" &&
                                roleName === "SuperAdmin" ? (
                                <div>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Approved",
                                        collection.id
                                      )
                                    }
                                  >
                                    Approve
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      changeCollectionStatus(
                                        "Pending",
                                        collection.id
                                      )
                                    }
                                  >
                                    Pending
                                  </Dropdown.Item>
                                </div>
                              ) : null}
                              <Dropdown.Divider />
                              <Dropdown.Item
                                onSelect={() => {
                                  if (
                                    collection.transactions &&
                                    collection.transactions.length
                                  ) {
                                    setDialogProps({
                                      open: true,
                                      title: "Exception",
                                      text:
                                        "This project cannot be removed because it already has " +
                                        collection.transactions.length +
                                        " transactions(s)",
                                      yesBtn: {
                                        title: "Okay",
                                        onTap: () => {
                                          setDialogProps(defaultDialog);
                                        },
                                      },
                                      onClose: () =>
                                        setDialogProps(defaultDialog),
                                    });
                                  }
                                  setDialogProps({
                                    open: true,
                                    title: "Confirmation",
                                    text: "This action is irreversible. Are you sure to proceed?",
                                    yesBtn: {
                                      title: "Yes",
                                      onTap: () => {
                                        removeStatus(collection.id);
                                        setDialogProps(defaultDialog);
                                      },
                                    },
                                    noBtn: {
                                      title: "No",
                                      onTap: () =>
                                        setDialogProps(defaultDialog),
                                    },
                                    onClose: () =>
                                      setDialogProps(defaultDialog),
                                  });
                                }}
                                variant="danger"
                                className="danger light sharp i-false"
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(CollectionList);
